/* User Control Styles */
.leaflet-control-user {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  outline: none; /* Prevent focus outline */
  /* Prevent text selection cursor */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; 
}

.user-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  outline: none; /* Prevent focus outline */
  /* Prevent text selection cursor */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; 
}

#user-control-icon {
  width: 24px;
  height: 24px;
  transition: fill 0.3s ease;
  outline: none; /* Prevent focus outline */
   /* Prevent text selection cursor */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; 
}

/* Profile Badge Styles */
.profile-badge {
  position: absolute;
  top: 40px;
  right: 0;
  width: 200px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 1000;
}

.profile-info {
  margin-bottom: 10px;
}

.profile-name {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
}

.profile-email {
  font-size: 12px;
  color: #666;
  margin-bottom: 5px;
  word-break: break-all;
}

.profile-actions {
  display: flex;
  justify-content: flex-end;
}

.logout-button {
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #d32f2f;
}